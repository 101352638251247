@font-face {
font-family: '__LeroyFont_714c04';
src: url(https://cdn.leroymerlin.com.br/ssr/0.187.0/_next/static/media/e5c8287afb95838b-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__LeroyFont_714c04';
src: url(https://cdn.leroymerlin.com.br/ssr/0.187.0/_next/static/media/d600e5f8940ef02d-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: bold;
}@font-face {font-family: '__LeroyFont_Fallback_714c04';src: local("Arial");ascent-override: 108.62%;descent-override: 26.94%;line-gap-override: 0.00%;size-adjust: 104.20%
}.__className_714c04 {font-family: '__LeroyFont_714c04', '__LeroyFont_Fallback_714c04'
}

